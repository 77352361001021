import { isEmpty } from "lodash-es";
import { parseAuthCookies } from "./utils";
import { decodeJwt } from "oidc-spa/tools/decodeJwt";

export function getTenantToken(cookies = {}) {
  if (isEmpty(cookies)) {
    cookies = parseAuthCookies();
  }
  const jwt = cookies["Api.Token"];
  if (jwt) {
    return decodeJwt(jwt);
  }
  return null;
}

function extractTenantFromMapping(data, url) {
  let configObj;

  // If data is a string, attempt to parse it.
  if (typeof data === "string") {
    try {
      configObj = JSON.parse(data);
    } catch (err) {
      console.error("Invalid JSON provided:", err);
      return null;
    }
  } else {
    // Assume it's already an object
    configObj = data;
  }

  // Iterate over each key in the config object
  for (const key in configObj) {
    if (Object.prototype.hasOwnProperty.call(configObj, key)) {
      const patterns = configObj[key];
      if (Array.isArray(patterns)) {
        for (const pattern of patterns) {
          // If pattern is an empty string, skip it
          if (!pattern) continue;

          try {
            const regex = new RegExp(pattern);
            if (regex.test(url)) {
              return key; // Return the key where we found a match
            }
          } catch (regexError) {
            // If pattern isn't a valid regex, handle gracefully or log
            console.warn(`Invalid regex pattern "${pattern}" for key "${key}":`, regexError);
          }
        }
      }
    }
  }

  // No matches found
  return null;
}

export const getTenant = (() => {
  let cachedTenant: string | null = null;

  return function (cookies = {}) {
    if (cachedTenant) {
      return cachedTenant;
    }

    // If logged in use tenant from jwt
    const token = getTenantToken(cookies);
    cachedTenant = (token as any)?.tenant;
    if (cachedTenant) {
      return cachedTenant;
    }

    // If not logged in check query param first
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const tenant = urlParams.get("tenant");
    if (tenant) {
      cachedTenant = tenant;
      return cachedTenant;
    }

    const hostname = window.location.hostname;

    const tenantMapping = import.meta.env.VITE_TENANT_MAPPING;
    const matchingTenant = extractTenantFromMapping(tenantMapping, hostname);
    if (matchingTenant) {
      cachedTenant = matchingTenant;
      return cachedTenant;
    }

    // If not logged in check hostname
    const parts = hostname.split(".");
    if (parts.length > 2) {
      cachedTenant = parts.slice(-2, -1)[0]; // e.g., 'pages' for frontend-9an.pages.dev
    } else {
      cachedTenant = parts[0]; // e.g., 'example' for 'example.com'
    }

    if (cachedTenant === "localhost") {
      cachedTenant = import.meta.env.VITE_DEFAULT_TENANT;
    }

    if (hostname.endsWith("pages.dev")) {
      cachedTenant = import.meta.env.VITE_DEFAULT_TENANT;
    }

    return cachedTenant;
  };
})();