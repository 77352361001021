import * as graphql from "../../resolvers-types";
import { getTenant } from "../library/tenantUtils";
import { areWeInImpersonationMode } from "../library/utils";

export const impersonateUserOverlay = {
  __typename: "Overlay",
  Id: "@Communify:impersonating-user-overlay",
  Visible: areWeInImpersonationMode(),
  Draggable: true,
  Style: {
    backgroundColor: "white",
    boxShadow:
      " 0px 2px 16px 1px rgba(0, 0, 0, 0.12), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 4px 5px -2px rgba(0, 0, 0, 0.20)",
    borderRadius: "5px",
  },
  Classes: ["impersonating-overlay"],
  Items: [
    {
      __typename: "SimpleContainer",
      Id: "14742134",
      UniqueName: "ImpersonatingDescription",
      Style: {
        padding: "10px",
      },
      Classes: ["impersonating-container"],
      Items: [
        {
          __typename: "SimpleContainer",
          Id: "14742136",
          UniqueName: "ImpersonatingUserOverlay",
          Style: {
            padding: "10px",
          },
          Classes: ["impersonating-description"],
          Items: [
            {
              __typename: "Image",
              Size: "small",
              Value: `simple-logo-${getTenant()}.png`,
              Style: {
                maxWidth: "50px",
              },
            },
            {
              __typename: "Label",
              Id: "82620166",
              DefaultValue: "You're impersonating",
              Style: {
                fontSize: "16px",
                fontWeight: "bold",
              },
            },
            {
              __typename: "Label",
              Id: "82620167",
              Bindings: {
                Value: "Global.User.Name",
              },
              Style: {
                fontSize: "16px",
                fontWeight: "bold",
              },
            },
          ],
        },
        {
          __typename: "SimpleContainer",
          Id: "14742135",
          UniqueName: "ImpersonatingButtonContainer",
          Style: {
            padding: "10px",
          },
          Classes: ["primary-button-container"],
          Items: [
            {
              __typename: "Button",
              Id: "59798312",
              Label: "Close Session",
              Style: {},
              Classes: ["primary-button"],
              Actions: [
                {
                  Trigger: "onClick",
                  CommandSet: {
                    Id: "1",
                    FirstCommandId: "1",
                    Commands: [
                      {
                        Id: "1",
                        Instruction: {
                          Name: "Logout",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  Position: { top: 74, left: window.innerWidth - 460 },
  Actions: [
    {
      Trigger: "onDrop",
      CommandSet: {
        Id: "1",
        FirstCommandId: "1",
        Commands: [
          {
            Id: "1",
            Instruction: {
              Name: "SetLocalStorage",
            },
            Parameters: [
              {
                Name: "Bindings",
                Value: {
                  "@Communify:impersonating-user-overlay": "Event.Position",
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as graphql.Overlay;
