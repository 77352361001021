import { INGInputFieldProps } from "../../library/NGFieldExtensions";
import { Input, InputAdornment, InputBaseProps, SlotProps, TextField, TextFieldOwnerState, TextareaAutosize } from "@mui/material";
import { useSignal, useSignalEffect } from "@preact/signals-react";
import React, { useRef, useContext, KeyboardEvent } from "react";
import IMask from "imask";
import { debounce, isNil, toNumber } from "lodash-es";
import { GridStackContext } from "../../layouts/NGGridStackLayout";
import NGIcon from "../NGIcon/NGIcon";
import { NGContextAutocomplete } from "../NGContextAutocomplete/NGContextAutocomplete";
import { GetFormatFromSite, setupHandlers, setupLocalState } from "../../library/dataService";
import {
  getTestId,
  getsxObject,
  getClassName,
  ignoreCustomHandlers,
  getCustomLabel,
  generateUID,
} from "../../library/utils";

export default function NGInputField({ config, context }: INGInputFieldProps) {
  const local = setupLocalState(
    config,
    {
      DefaultValue: useSignal(config.DefaultValue ?? ""),
      Value: useSignal(config.DefaultValue ?? ""),
      Label: useSignal(config.Label ?? ""),
      Visible: useSignal(config.Visible ?? true),
      Multiline: useSignal(config.Multiline ?? false),
      MaxRows: useSignal(config.MaxRows ?? null),
      MinRows: useSignal(config.MinRows ?? null),
      Rows: useSignal(config.Rows ?? null),
      Disabled: useSignal(config.Disabled ?? false),
      Classes: useSignal(config.Classes ?? ""),
      Style: useSignal(config.Style ?? {}),
      FocusOnDisplay: useSignal(config.FocusOnDisplay ?? false),
      Debounce: useSignal(config.Debounce ?? 1000),
      InputType: useSignal(config.InputType ?? "text"),
      Valid: useSignal(config.Valid ?? { success: true, reasons: [] }),
      Format: useSignal(config.Format ?? ""),
    },
    context
  );

  const maskedValue = useSignal("");
  const inputRef = useRef<HTMLInputElement>(null);
  const maskRef = useRef<InstanceType<typeof IMask.InputMask> | null>(null);
  const handlers = setupHandlers(config, context);
  const handlersKeys = Object.keys(handlers);

  const debouncedHandler = debounce((fn, event, value) => {
    if (!isNil(fn)) {
      fn(event, value);
    }
  }, local.Debounce.value);

  const gridCtx = useContext(GridStackContext);

  const inputProps: any = {}
  const InputProps: SlotProps<React.ElementType<InputBaseProps>, object, TextFieldOwnerState> = {};

  if (local.InputType.value === "number") {
    inputProps.pattern = "[0-9]*[.,]?[0-9]*"
  }

  if (config.Multiline && config.MultilineAutosize) {
    InputProps.inputComponent = TextareaAutosize;
    if (!isNil(config.MinRows)) InputProps.minRows = config.MinRows;
    if (!isNil(config.MaxRows)) InputProps.maxRows = config.MaxRows;
  }

  if (!isNil(config.Min)) inputProps.min = config.Min;
  if (!isNil(config.Max)) inputProps.max = config.Max;
  if (!isNil(config.MaxLength)) inputProps.maxLength = config.MaxLength;

  if (config.Adornment?.Position) {
    const position = config.Adornment.Position.toLowerCase();
    const iconName = config.Adornment.Icon?.IconName ?? "Search";
    InputProps[`${position}Adornment`] = (
      <InputAdornment position={position as "start" | "end"}>
        <NGIcon config={{ Id: generateUID(), IconName: iconName }} context={context} />
      </InputAdornment>
    );
  }

  local.InputProps = useSignal(InputProps);
  local.inputProps = useSignal(inputProps);
  local.InDesignMode = useSignal(context?.InDesignMode ?? false);

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
  };

  useSignalEffect(() => {
    if (inputRef.current && local.FocusOnDisplay.value) {
      inputRef.current.focus();
    }
  });

  const transformMaskOptions = (options: any) => {
    const resolved = { ...options };
    const typeMapping: { [key: string]: any } = {
      Number: Number,
      Date: Date,
      RegExp: RegExp,
      // add additional mappings here if needed
    };

    if (typeof resolved.mask === "string" && typeMapping[resolved.mask]) {
      resolved.mask = typeMapping[resolved.mask];
    }
    if (resolved.blocks) {
      const newBlocks = { ...resolved.blocks };
      Object.keys(newBlocks).forEach((blockKey) => {
        const block = { ...newBlocks[blockKey] };
        if (typeof block.mask === "string" && typeMapping[block.mask]) {
          block.mask = typeMapping[block.mask];
        }
        newBlocks[blockKey] = block;
      });
      resolved.blocks = newBlocks;
    }
    return resolved;
  };

  const handleMaskAccept = (maskInstance) => () => {
    maskedValue.value = maskInstance.value;
  };

  const handleMaskComplete = (maskInstance) => () => {
    // If typedValue is defined, use it (for example for Number or Date masks).
    if (maskInstance.typedValue !== undefined && maskInstance.typedValue !== null) {
      if (config.InputType === "number") {
        local.Value.value = Number(maskInstance.typedValue);
      } else {
        local.Value.value = maskInstance.typedValue;
      }
    } else {
      local.Value.value = maskInstance.unmaskedValue;
    }
    maskedValue.value = maskInstance.value;
    handlersKeys.forEach((handler) => {
      if (handler.toLowerCase().includes("onchange")) {
        debouncedHandler(handlers[handler], null, local.Value.value);
        // handlers.onChange(e, local.Value.value);
      }
    });
  };

  const updateMaskValues = (maskInstance: InstanceType<typeof IMask.InputMask>) => {
    if (local.Value.value) {
      if (maskInstance.typedValue !== undefined) {
        maskInstance.typedValue = local.Value.value;
      } else {
        maskInstance.unmaskedValue = local.Value.value.toString();
      }
      maskedValue.value = maskInstance.value;
    }
  };

  useSignalEffect(() => {
    const formatName = local.Format.value;
    if (formatName && inputRef.current) {
      const format = GetFormatFromSite(formatName);
      if (format?.Mask) {
        const maskOptions = transformMaskOptions(format.Mask);
        const maskInstance = IMask(inputRef.current, maskOptions);
        maskRef.current = maskInstance;

        maskInstance.on("accept", handleMaskAccept(maskInstance));
        maskInstance.on("complete", handleMaskComplete(maskInstance));

        updateMaskValues(maskInstance);

        return () => maskInstance.destroy();
      } else if (format?.NumberFormat) {
        const { Prefix = "", Suffix = "", MaximumFractionDigits = 0, GroupSeparator = "," } = format.NumberFormat;
        const maskPattern = `${Prefix}num${Suffix}`;
        const maskOptions = {
          mask: maskPattern,
          blocks: {
            num: {
              mask: Number,
              scale: MaximumFractionDigits,
              padFractionalZeros: true,
              normalizeZeros: true,
              thousandsSeparator: GroupSeparator,
              radix: ".",
            },
          },
          lazy: false,
        };

        const maskInstance = IMask(inputRef.current, maskOptions);
        maskRef.current = maskInstance;

        maskInstance.on("accept", handleMaskAccept(maskInstance));
        maskInstance.on("complete", handleMaskComplete(maskInstance));

        updateMaskValues(maskInstance);

        return () => maskInstance.destroy();
      }
    }
  });

  useSignalEffect(() => {
    if (maskRef.current) {
      if (!local.Value.value) {
        maskRef.current.unmaskedValue = "";
        maskedValue.value = "";
      } else {
        if (maskRef.current.typedValue !== undefined) {
          maskRef.current.typedValue = local.Value.value;
        } else {
          maskRef.current.unmaskedValue = local.Value.value.toString();
        }
        maskedValue.value = maskRef.current.value;
      }
      maskRef.current.updateValue();
    }
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!local.Format.value) {
      const { value } = e.target;
      switch (local.InputType.value) {
        case "number": {
          const numericValue = toNumber(value);
          local.Value.value = isNaN(numericValue) ? null : numericValue;
          break;
        }
        case "date": {
          const dateValue = new Date(value);
          local.Value.value = isNaN(dateValue.getTime()) ? null : dateValue;
          break;
        }
        default:
          local.Value.value = value;
          break;
      }
      handlersKeys.forEach((handler) => {
        if (handler.toLowerCase().includes("onchange")) {
          debouncedHandler(handlers[handler], e, local.Value.value);
        }
      });
    }
  };

  const inputType = local.Format.value ? "text" : local.InputType.value;

  return (
    <>
      {local.Visible.value && (
        <TextField
          data-testid={getTestId(config)}
          data-type={config.__typename}
          inputRef={inputRef}
          sx={getsxObject(local.Style.value)}
          className={getClassName(local.Classes)}
          value={local.Format.value ? maskedValue.value ?? "" : local.Value.value ?? ""}
          type={inputType ?? "text"}
          error={!local.Valid.value.success}
          label={getCustomLabel(config.Label)}
          helperText={!local.Valid.value.success && local.Valid.value.reasons?.map((r) => r.message).join(". ")}
          autoComplete={config.AutoComplete as string}
          autoFocus={config.AutoFocus as boolean}
          color={config.Color as any}
          disabled={gridCtx.InDesignMode || local.Disabled.value}
          fullWidth={config.FullWidth as boolean}
          margin={config.Margin as any}
          maxRows={local.MaxRows.value}
          minRows={local.MinRows.value}
          rows={local.Rows.value}
          variant={config.Variant as any}
          multiline={local.Multiline.value}
          placeholder={config.Placeholder as string}
          onKeyDown={onKeyDown}
          required={config.Required as boolean}
          select={config.Select as boolean}
          size={config.Size as any}
          slotProps={{
            input: local.InputProps.value,
            htmlInput: local.inputProps.value,
          }}
          {...ignoreCustomHandlers(handlers)}
          onChange={handleChange}
        />
      )}
      {config.ContextAutocomplete && inputRef.current && (
        <NGContextAutocomplete
          config={config.ContextAutocomplete}
          context={{ ...context, Element: inputRef.current }}
        />
      )}
    </>
  );
}
